import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function BlogPost({ data }) {
    const post = data.post
    const blogListings = data.blogListings
    return (
        <Layout>
            <SEO title={post.frontmatter.title} description={post.excerpt} />
            <div>
                <h1>{post.frontmatter.title}</h1>
                <p style={{ fontStyle: `italic`}}> - by the NFL DFS team </p>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        post: markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
            }
            excerpt
        }
        blogListings: allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            totalCount
            edges {
              node {
                id
                frontmatter {
                  title
                  date(formatString: "MMMM DD, YYYY")
                }
                fields {
                  slug
                }
                excerpt
                timeToRead
                html
              }
            }
          }
    }
`

//+ {blogListings.totalCount} //